<template>
  <div v-if="$userInfo.isSupportUser"
    class="tile is-parent pl-1">
    <section class="tile is-child box">
      <div>
        <span class="subtitle">Manage Email Route</span>
      </div>
      <div class="pt-5">
        <div>
          <div class="field has-addons">
            <p class="control is-expanded">
              <input v-model="serviceProviderEmail"
                @input="onlyAcceptAlphaNumeric"
                @blur="validateServiceProviderEmail"
                class="input fix-rounded mb-2"
                type="text"
                placeholder="Service Provider Email">
              <small v-if="serviceProviderEmailExist"
                class="tag  is-danger is-flex is-justify-content-center mb-2"
                style="width: 50%">
                This email was taken, please choose another one
              </small>
            </p>
            <p class="control">
              <button class="button is-static">
                {{ `@${serviceProviderDomainName}` }}
              </button>
            </p>
          </div>
        </div>

        <input v-model="companyEmail"
          class="input fix-rounded mb-2"
          type="text"
          placeholder="Company Email"
          disabled>
        <input v-model="companyName"
          class="input fix-rounded"
          type="text"
          placeholder="Company Name"
          disabled>
      </div>
      <div v-if="companyEmail && companyName">
        <div class="pt-5 pb-5">
          <button class="button is-success mr-5"
            @click="createEmailRoute"
            :disabled="routeExists || isServiceProviderEmailExist">Create Route</button>
          <button class="button is-danger"
            @click="deleteEmailRoute"
            :disabled="!routeExists">Delete Route</button>
        </div>
        <table class="table is-bordered is-striped is-narrow is-fullwidth table is-hoverable"
          ref="table">
          <thead>
            <tr>
              <th>Route</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ routeMatchInfo }}<br>{{ routeForwardInfo }}
              </td>
              <td>
                {{ routeDescription }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
  <div v-else
    class="is-flex is-justify-content-center pt-5 ">
    <div class="notification is-danger is-flex is-justify-content-center is-size-4"
      style="width: 50%">
      You do not have permissions on this page
    </div>
  </div>
</template>

<script>
import { EmailService } from '@/services'
import { mapGetters } from 'vuex'

export default {
  name: 'CompanyEmailRouteManage',

  data: () => {
    return {
      companyRoute: null,
      serviceProviderDomainName: '',
      serviceProviderEmail: '',
      serviceProviderEmailExist: false
    }
  },

  computed: {
    ...mapGetters({
      company: 'company/company'
    }),
    companyEmail() {
      return this.company.setting ? this.company.setting.eSetEmail : null
    },
    companyName() {
      return this.company.setting ? this.company.setting.eSetDisplayName : null
    },
    isServiceProviderEmailExist() {
      return this.serviceProviderEmail && this.serviceProviderEmailExist
    },
    serviceProviderSuggestedEmail() {
      const serviceProviderEmail = this.company.setting ? this.company.setting.serviceProviderEmailId : null
      if (serviceProviderEmail == null) {
        serviceProviderEmail = this.removePatternFromString(this.companyName)
      }
      return serviceProviderEmail.replace(this.serviceProviderDomainName, '')
    },

    routeExists() {
      return !!this.companyRoute
    },
    routeDescription() {
      return this.companyRoute ? this.companyRoute['description'] : null
    },
    routeForwardInfo() {
      return this.companyRoute ? this.companyRoute['actions'].join('') : null
    },
    routeMatchInfo() {
      return this.companyRoute ? this.companyRoute['expression'] : null
    }
  },

  async created() {
    await this.checkEmailSetting()
    await this.getRoute()
    await this.getServiceProviderDomainName()
  },

  methods: {
    async checkEmailSetting() {
      if (!this.company.setting) {
        await this.$store.dispatch('company/getCompanySetting')
      }

      if (!this.company.setting || !this.company.setting.eSetEmail || !this.company.setting.eSetDisplayName) {
        this.$notification.error('Error', 'Please set the company email and name first')
      }
    },
    async createEmailRoute() {
      try {
        this.$showSpinner()
        await EmailService.createRoute(this.serviceProviderEmail)
        await this.handleSuccessRouteUpdate('Company route created')
      } catch (e) {
        this.handleError('Failed create a route')
      }
    },
    async deleteEmailRoute() {
      try {
        this.$showSpinner()
        await EmailService.deleteRoute()
        await this.handleSuccessRouteUpdate('Company route deleted')
      } catch (e) {
        this.handleError('Failed delete a route')
      }
    },
    async getRoute() {
      try {
        this.$showSpinner()
        this.companyRoute = await EmailService.getRoute()
        this.$hideSpinner()
      } catch (e) {
        this.handleError('Failed to get route')
      }
    },
    async getServiceProviderDomainName() {
      try {
        this.$showSpinner()
        this.serviceProviderDomainName = await EmailService.getServiceProviderDomainName()
        this.$hideSpinner()

        this.serviceProviderEmail = this.serviceProviderSuggestedEmail
      } catch (e) {
        this.handleError('Failed to get Service Provider Domain Name')
      }
    },
    async validateServiceProviderEmail() {
      try {
        this.$showSpinner()
        this.onlyAcceptAlphaNumeric()
        if (this.serviceProviderEmail != '') {
          const isExist = await EmailService.validateServiceProviderEmail(this.serviceProviderEmail)
          this.serviceProviderEmailExist = isExist
        }

        this.$hideSpinner()
      } catch (e) {
        this.handleError('Failed to get Service Provider Domain Name')
      }
    },
    handleError($message) {
      this.$hideSpinner()
      this.$notification.error('Error', $message)
    },
    async handleSuccessRouteUpdate($message) {
      await this.getRoute()
      this.$hideSpinner()
      this.$notification.success('Success', $message)
    },
    removePatternFromString(inputString) {
      if (!inputString) return ''
      return inputString
        .toLowerCase()
        .replace(/['` ~!@#$%^&*()_+\-=\[\]{}|;':"\\,.<>\/?]/g, '')
        .replace('ltd', '')
        .replace('pty', '')
        .replace('sdn', '')
        .replace('bhd', '')
    },
    onlyAcceptAlphaNumeric() {
      var regex = /^[a-zA-Z0-9]*$/ // Regular expression to match alphanumeric characters

      if (!regex.test(this.serviceProviderEmail)) {
        // If the input value contains non-alphanumeric characters, remove them
        this.serviceProviderEmail = this.serviceProviderEmail.replace(/[^a-zA-Z0-9]/g, '')
      }
    }
  }
}
</script>
