<template>
  <div>
    <div v-if="$userInfo.isSupportUser"
      class="tile is-parent pl-1">
      <!-- <app-detail-header :show-spinner="false"
        ref="sectionHeader" /> -->
      <div class="tile is-child box">
        <section class="container">
          <label class="label">Enable Assessment History</label>
          <div class="control">
            <div class="pretty p-switch p-fill">
              <input type="checkbox"
                @change="assessmentHistoryChange"
                :checked="companySetting.assessmentEnabled">
              <div class="state p-primary">
                <label>{{ companySetting.assessmentEnabled ? 'Yes' : 'No' }}</label>
              </div>
            </div>
          </div>
          <div class="buttons is-right">
            <div class="is-flex is-justify-content-right pt-3">
              <button class="button is-outlined"
                @click="reset">Reset</button>
            </div>
            <div class="is-flex is-justify-content-right pt-3">
              <button class="button is-success is-outlined"
                @click="save">Save</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { CompanyService } from '@/services'
import { mapActions } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'NewFeaturesControl',
  components: {},
  mixins: [],
  props: {},
  data: () => {
    return {
      companySetting: null
    }
  },
  computed: {},
  watch: {},
  created() {
    this.companySetting = _cloneDeep(this.$company.setting)
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions('company', ['getCompanySetting']),
    async save() {
      this.isLoading = true
      try {
        this.$showSpinner()
        await CompanyService.updateCompanySettings(this.companySetting)
        await this.getCompanySetting()
        this.$notification.success('Success', 'Access updated')
      } catch (e) {
        this.$notification.error('Updating features error', `Feature update failed. Error: ${e}`)
      }
      this.isLoading = false
      this.$hideSpinner()
    },
    reset() {
      this.companySetting = _cloneDeep(this.$company.setting)
    },
    async assessmentHistoryChange() {
      this.companySetting.assessmentEnabled = !this.companySetting.assessmentEnabled
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.list-style-reset {
  margin-top: 1em;
  li {
    list-style: initial;
    list-style-type: disc;
    margin-left: 2em;
  }
}
</style>
