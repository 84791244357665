<template>
  <div v-if="$company.setting"
    class="tile is-parent pl-1">
    <section class="tile is-child box">
      <div class="subtitle">Company Setting</div>
      <div class="field">
        <label class="label">Item Description AutoComplete</label>
        <div class="control">
          <div class="pretty p-switch p-fill">
            <input type="checkbox"
              @change="onChangeAutoCompleteSwitch"
              :checked="$company.setting.itemAutoCompleteEnabled">
            <div class="state p-primary">
              <label>{{ $company.setting.itemAutoCompleteEnabled ? "On" : "Off" }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Parts Control Enabled</label>
        <div class="control">
          <div class="pretty p-switch p-fill">
            <input type="checkbox"
              @change="onChangePartsControlSwitch($event, 'PartsControlEnabled')"
              :checked="$company.setting.partsControlEnabled">
            <div class="state p-primary">
              <label>{{ $company.setting.partsControlEnabled ? "On" : "Off" }}</label>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import _debounce from 'lodash.debounce'

export default {
  name: 'CompanySetting',
  components: {},
  mixins: [],
  props: {},
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    onChangeAutoCompleteSwitch: _debounce(function (event) {
      this.setItemAutoCompleteEnabled(event.target.checked)
    }, 300),
    onChangePartsControlSwitch: _debounce(function (event, settingName) {
      this.alterCompanySettings({
        settingName,
        value: event.target.checked
      })
    }, 300)
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>
