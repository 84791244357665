<template>
  <div>
    <div v-if="$userInfo.isSupportUser"
      class="tile is-parent pl-1">
      <!-- <app-detail-header :show-spinner="false"
        ref="sectionHeader" /> -->
      <div class="tile is-child box">
        <section class="container">
          <div>
            <div class="field">
              <p class="control has-icons-left">
                <input class="input is-primary"
                  id="filter"
                  type="text"
                  v-model="filterKeyword"
                  placeholder="Filter">
                <span class="icon is-left">
                  <i class="mdi mdi-magnify mdi-20px" />
                </span>
              </p>
            </div>
          </div>
          <div class="is-flex is-justify-content-right pt-3">
            <button class="button is-primary"
              @click="enableV2">Enable All</button>
          </div>
          <hr>
          <div v-for="(p, idx) in filteredPermissionRecords"
            class="item"
            :key="idx">
            <div class="columns">
              <div class="column is-2">
                <h2 class="subtitle is-6">
                  {{ p.name }}</h2>
              </div>
              <div class="column is-narrow">
                <div class="field has-addons">
                  <p class="control">
                    <a class="button is-static is-small">
                      Version
                    </a>
                  </p>
                  <p class="control">
                    <span class="select is-small">
                      <select v-model="p.version"
                        @change="updateVersion(p, $event.target.value)">
                        <option v-for="(val, key) in p.versions"
                          :value="key"
                          :key="key">{{ key }}</option>
                      </select>
                    </span>
                  </p>
                </div>
              </div>
              <div class="column">
                <div v-for="(c, index) in p.versions[p.version]"
                  class="field has-addons"
                  :key="index">
                  <p class="control">
                    <button class="button is-static is-small">
                      {{ c.route }}
                    </button>
                  </p>
                  <p class="control is-expanded">
                    <input class="input is-small"
                      :value="c.link"
                      readonly>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div v-else
      class="is-flex is-justify-content-center pt-5 ">
      <div class="notification is-danger is-flex is-justify-content-center is-size-4"
        style="width: 50%">
        You do not have permissions on this page
      </div>
    </div>
  </div>
</template>

<script>
import { CompanyService } from '@/services'

export default {
  name: 'CompanyMenuVersionAccess',
  components: {},
  data() {
    return {
      isLoading: true,
      permissionRecords: [],
      filterKeyword: ''
    }
  },

  computed: {
    filteredPermissionRecords: function () {
      const self = this
      if (this.filterKeyword.length > 0) {
        return this.permissionRecords.filter(function (p) {
          return p.name && p.name.toLowerCase().indexOf(self.filterKeyword.toLowerCase()) > -1
        })
      } else {
        return this.permissionRecords
      }
    }
  },

  mounted() {
    CompanyService.getCompanyPermissionRecords().then(
      (response) => {
        this.permissionRecords = response.data.records
        this.isLoading = false
      },
      () => {
        this.isLoading = false
      }
    )
  },

  methods: {
    updateVersion: async function (p, version) {
      this.isLoading = true
      try {
        await CompanyService.updateCompanyPermissionRecord(p.id, version)
        this.$notification.success('Success', 'Access updated')
      } catch (e) {
        this.$notification.error('Error', `Update failed for Access. Error: ${e}`)
      }
      this.isLoading = false
    },
    async enableV2() {
      try {
        await CompanyService.enableAllV2()
        const result = await CompanyService.getCompanyPermissionRecords()
        this.permissionRecords = result.data.records
        this.$notification.success('Success', 'V2 is now enabled')
      } catch (e) {
        this.$notification.error('Error', `Update failed. Error: ${e}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px solid #f2f2f2;
  padding: 0.5em;

  &:hover {
    background-color: #f2f2f2;
  }
}
</style>
